import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { RequiredUserPermissionDirective } from './directives/required-user-permission/required-user-permission.directive';
import { UserIsAdminRoleRequiredDirective } from './directives/user-is-admin-role-required/user-is-admin-role-required.directive';

@NgModule({
  declarations: [RequiredUserPermissionDirective, UserIsAdminRoleRequiredDirective],
  exports: [RequiredUserPermissionDirective, UserIsAdminRoleRequiredDirective],
  imports: [CommonModule, SharedModule],
})
export class UserPermissionsModule {}
