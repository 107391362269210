import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

import { MainLayoutComponent } from './pages/main-layout/main-layout.component';
import { PageLayoutBasicComponent } from './components/page-layout-basic/page-layout-basic.component';
import { PageLayoutMainComponent } from './components/page-layout-main/page-layout-main.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalModule } from '../modal/modal.module';
import { I18nModule } from '../i18n/i18n.module';
import { UserPermissionsModule } from '../user-permissions/user-permissions.module';

@NgModule({
  declarations: [
    MainLayoutComponent,
    PageLayoutBasicComponent,
    PageLayoutMainComponent,
    SidebarComponent,
    SidebarMenuComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    RouterModule,
    TranslateModule,
    NgbDropdownModule,
    ModalModule,
    I18nModule,
    UserPermissionsModule,
  ],
  exports: [PageLayoutBasicComponent, PageLayoutMainComponent],
})
export class LayoutModule {}
