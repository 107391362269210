<div ngbDropdown class="dropdown-toggle" placement="bottom-right">
  <button class="button button-toggle-visibility" type="button" id="language-dropdown" ngbDropdownToggle>
    <span>{{ currentLanguage$ | async | uppercase }}</span>

    <span class="icon icon-globe"></span>
  </button>

  <div class="dropdown" ngbDropdownMenu aria-labelledby="language-dropdown">
    <button
      class="button gray"
      *ngFor="let language of languageList"
      [ngClass]="{ 'is-selected': (currentLanguage$ | async) === language.code }"
      (click)="updateLanguage(language)"
    >
      {{ language.name }}
    </button>
  </div>
</div>
