import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { LanguageModel } from '../../models/language/language.model';
import { CurrentLanguageService } from '../../services/current-language/current-language.service';
import { LanguageService } from '../../services/language/language.service';
import { ListModel } from '../../../shared/models/list/list.model';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { LanguageListDataPageModel } from '../../models/language-list-data-page/language-list-data-page.model';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  standalone: false,
})
export class LanguageDropdownComponent extends SubscriptionStoreComponent implements OnInit {
  @ViewChild(NgbDropdown) public dropdown!: NgbDropdown;

  public languageList: Array<LanguageModel>;
  public currentLanguage$: Observable<string>;
  public currentLanguage: string;

  constructor(
    private currentLanguageService: CurrentLanguageService,
    private languageService: LanguageService
  ) {
    super();

    this.languageList = [];
    this.currentLanguage$ = this.currentLanguageService.getCurrentLanguageAsObservable();
    this.currentLanguage = this.currentLanguageService.getCurrentLanguage();
  }

  public ngOnInit(): void {
    this.initLanguageList();
  }

  public updateLanguage(language: LanguageModel): void {
    if (this.currentLanguage !== language.code) {
      this.currentLanguageService.updateCurrentLanguage(language.code);
      this.reloadWindow();
    }

    this.dropdown.close();
  }

  private initLanguageList(): void {
    const languageListDataPage: LanguageListDataPageModel = {
      page: 1,
      itemsPerPage: 100,
    };

    this.subscription = this.languageService.getLanguageList(languageListDataPage).subscribe({
      next: (languages: ListModel<LanguageModel>) => {
        this.setLanguageList(languages.items);
      },
    });
  }

  private setLanguageList(languageList: Array<LanguageModel>): void {
    this.languageList = languageList;
  }

  private reloadWindow(): void {
    window.location.reload();
  }
}
