import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { UserRolePermissionDto } from '../../dtos/user-role-permissions/user-role-permissions.dto';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsHttpService {
  constructor(private httpClient: HttpClient) {}

  public getUserRolePermissions(): Observable<Array<UserRolePermissionDto>> {
    return this.httpClient
      .get<ApiResponseDto<Array<UserRolePermissionDto>>>(ApiUrlUtil.getApiUrl('user-role/users/permissions'))
      .pipe(map((response: ApiResponseDto<Array<UserRolePermissionDto>>) => response.data));
  }
}
