import { Component } from '@angular/core';

import { SidebarService } from '../../services/sidebar/sidebar.service';
import { SidebarItemModel } from '../../models/sidebar-item/sidebar-item.model';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: false,
})
export class SidebarComponent extends SubscriptionStoreComponent {
  public isClosed: boolean;
  public sidebarItems: Array<SidebarItemModel>;

  constructor(private sidebarService: SidebarService) {
    super();

    this.isClosed = false;
    this.sidebarItems = this.sidebarService.getSidebarItems();
  }

  public toggleSidebar(): void {
    this.isClosed = !this.isClosed;
  }
}
