import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';

@NgModule({
  declarations: [LanguageDropdownComponent],
  exports: [LanguageDropdownComponent],
  imports: [CommonModule, NgbDropdownModule],
})
export class I18nModule {}
