<header class="header">
  <ul class="header-links">
    <li class="user" *ngIf="currentUser">
      {{ currentUser.username }}
    </li>

    <li class="list-item">
      <div ngbDropdown class="dropdown-toggle" placement="bottom-right">
        <button class="button-icon button-toggle-visibility" type="button" id="user-dropdown" ngbDropdownToggle>
          <span class="icon icon-user-525050-24x24"></span>
        </button>

        <div class="dropdown" ngbDropdownMenu aria-labelledby="user-dropdown">
          <button class="button" (click)="logoutEvent()">
            <span class="icon icon-logout-0C9D58-18x18"></span>

            <span class="button-label">{{ 'layout.header.dropdown.logout' | translate }}</span>
          </button>
        </div>
      </div>
    </li>

    <li>
      <app-language-dropdown></app-language-dropdown>
    </li>
  </ul>
</header>
