import { Injectable } from '@angular/core';

import { map, Observable, of, tap } from 'rxjs';

import { AuthTokenService } from '../../../auth/services/auth-token/auth-token.service';
import { UserRolePermissionsModel } from '../../models/user-role-permissions/user-role-permissions.model';
import { UserPermissionsService } from '../user-permissions/user-permissions.service';
import { UserRolePermissionNameModel } from '../../models/user-role-permission-name/user-role-permission-name.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserPermissionsService {
  private rolePermissionList: Array<UserRolePermissionsModel>;

  constructor(
    private authTokenService: AuthTokenService,
    private userPermissionsService: UserPermissionsService
  ) {
    this.rolePermissionList = [];
  }

  public initPermissions(): Observable<void> {
    if (!this.authTokenService.isAccessToken()) {
      return of(void 0);
    }

    return this.updatePermissions();
  }

  public getRolePermissionList(): Array<UserRolePermissionsModel> {
    return this.rolePermissionList;
  }

  public getHasPermissionForSidebarItemByUserRolePermissionName(permissionName: UserRolePermissionNameModel): boolean {
    return !!this.getRolePermissionList().find((permission: UserRolePermissionsModel) => permission.name === permissionName)?.hasPermission;
  }

  public updatePermissions(): Observable<void> {
    return this.userPermissionsService.getUserRolePermissions().pipe(
      tap((rolePermissionList: Array<UserRolePermissionsModel>) => {
        this.setRolePermissionList(rolePermissionList);
      }),
      map(() => void 0)
    );
  }

  private setRolePermissionList(rolePermissionList: Array<UserRolePermissionsModel>): void {
    this.rolePermissionList = rolePermissionList;
  }
}
