import { UserRolePermissionDto } from '../../dtos/user-role-permissions/user-role-permissions.dto';
import { UserRolePermissionsModel } from '../../models/user-role-permissions/user-role-permissions.model';

export class UserRolePermissionsCreator {
  public static create(dto: UserRolePermissionDto): UserRolePermissionsModel {
    return {
      name: dto.name,
      hasPermission: dto.value,
    };
  }
}
