import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LanguageEnum } from '../../enums/language/language.enum';
import { LanguageService } from '../language/language.service';
import { ListModel } from '../../../shared/models/list/list.model';
import { LanguageModel } from '../../models/language/language.model';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { LanguageListDataPageModel } from '../../models/language-list-data-page/language-list-data-page.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentLanguageService {
  private currentLanguage$: BehaviorSubject<string>;
  private currentLanguageList: Array<LanguageModel>;
  private readonly currentLanguageKey: string;
  private readonly defaultLanguage: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService
  ) {
    this.currentLanguageKey = 'currentLanguage';
    this.defaultLanguage = LanguageEnum.pl;
    this.currentLanguage$ = new BehaviorSubject<string>(this.defaultLanguage);
    this.currentLanguageList = [];
  }

  public initCurrentLanguage(): Observable<void> {
    const languageListDataPage: LanguageListDataPageModel = {
      page: 1,
      itemsPerPage: 100,
    };

    return this.languageService.getLanguageList(languageListDataPage).pipe(
      tap((languageList: ListModel<LanguageModel>) => {
        this.setCurrentLanguageList(languageList.items);
        this.initCurrentLanguageByBrowserOrLocalStorage();
      }),
      map(() => void 0)
    );
  }

  public getCurrentLanguageAsObservable(): Observable<string> {
    return this.currentLanguage$.asObservable();
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage$.value;
  }

  public updateCurrentLanguage(languageCode: string): void {
    this.setCurrentLanguageToLocalStorage(languageCode);
    this.setCurrentLanguage(languageCode);
  }

  private initCurrentLanguageByBrowserOrLocalStorage(): void {
    const currentLanguageCodeInLocalStorage: string | null = this.getCurrentLanguageCodeFromLocalStorage();
    const currentLanguageCodeInBrowser: string | null = this.getCurrentLanguageFromBrowser();

    if (currentLanguageCodeInLocalStorage && this.isLanguageInCurrentLanguageList(currentLanguageCodeInLocalStorage)) {
      this.updateCurrentLanguage(currentLanguageCodeInLocalStorage);
    } else if (currentLanguageCodeInBrowser && this.isLanguageInCurrentLanguageList(currentLanguageCodeInBrowser)) {
      this.updateCurrentLanguage(currentLanguageCodeInBrowser);
    } else {
      this.updateCurrentLanguage(this.defaultLanguage);
    }
  }

  private isLanguageInCurrentLanguageList(languageCode: string): boolean {
    return !!this.currentLanguageList.find((language: LanguageModel) => language.code.toLowerCase() === languageCode.toLowerCase());
  }

  private getCurrentLanguageCodeFromLocalStorage(): string | null {
    return this.localStorageService.getItem(this.currentLanguageKey);
  }

  private getCurrentLanguageFromBrowser(): string | null {
    if (!this.document.defaultView) {
      return null;
    }

    return this.document.defaultView.navigator.language.split('-')[0].toUpperCase();
  }

  private setCurrentLanguageList(currentLanguageList: Array<LanguageModel>): void {
    this.currentLanguageList = currentLanguageList;
  }

  private setCurrentLanguage(language: string): void {
    this.translateService.use(language.toLowerCase());
    this.currentLanguage$.next(language);
  }

  private setCurrentLanguageToLocalStorage(languageCode: string): void {
    this.localStorageService.setItem(this.currentLanguageKey, languageCode);
  }
}
