import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { CurrentUserService } from '../../../users/services/current-user/current-user.service';

@Directive({
  selector: '[appUserIsAdminRoleRequired]',
  standalone: false,
})
export class UserIsAdminRoleRequiredDirective implements OnInit {
  @Input() public appUserIsAdminRoleRequired: boolean | undefined;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private currentUserService: CurrentUserService
  ) {}

  public ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    const isUserEntitled: boolean = this.getIsUserEntitled();

    if (isUserEntitled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private getIsUserEntitled(): boolean {
    if (!this.appUserIsAdminRoleRequired) {
      return true;
    }

    return this.currentUserService.getCurrentUserRoleIsAdmin();
  }
}
