import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { UserRolePermissionsCreator } from '../../creators/user-role-permissions/user-role-permissions.creator';
import { UserRolePermissionDto } from '../../dtos/user-role-permissions/user-role-permissions.dto';
import { UserRolePermissionsModel } from '../../models/user-role-permissions/user-role-permissions.model';
import { UserPermissionsHttpService } from '../user-permissions-http/user-permissions-http.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  constructor(private userPermissionsHttpService: UserPermissionsHttpService) {}

  public getUserRolePermissions(): Observable<Array<UserRolePermissionsModel>> {
    return this.userPermissionsHttpService
      .getUserRolePermissions()
      .pipe(
        map((permissions: Array<UserRolePermissionDto>) =>
          permissions.map((permission: UserRolePermissionDto) => UserRolePermissionsCreator.create(permission))
        )
      );
  }
}
