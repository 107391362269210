import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { UserRolePermissionNameModel } from '../../models/user-role-permission-name/user-role-permission-name.model';
import { CurrentUserPermissionsService } from '../../services/current-user-permissions/current-user-permissions.service';

@Directive({
  selector: '[appRequiredUserPermission]',
  standalone: false,
})
export class RequiredUserPermissionDirective implements OnInit {
  @Input() public appRequiredUserPermission: UserRolePermissionNameModel | undefined;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private currentUserPermissionsService: CurrentUserPermissionsService
  ) {}

  public ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    const isUserEntitled: boolean = this.getIsUserEntitled();

    if (isUserEntitled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private getIsUserEntitled(): boolean {
    if (!this.appRequiredUserPermission) {
      return true;
    }

    return this.currentUserPermissionsService.getHasPermissionForSidebarItemByUserRolePermissionName(this.appRequiredUserPermission);
  }
}
