<div class="sidebar-menu" [class.is-closed]="isClosed">
  <ng-container *ngFor="let item of sidebarItems">
    <ng-container *appRequiredUserPermission="item.userRolePermissionName">
      <ng-container *appUserIsAdminRoleRequired="item.isAdminRoleRequired">
        <button class="button" routerLinkActive="is-active" [routerLink]="item.url" [routerLinkActiveOptions]="{ exact: item.exact }">
          <div class="icon-wrapper">
            <span class="icon {{ item.iconClass }}"></span>
          </div>

          <span class="button-label">{{ item.label | translate }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
